<template>
  <div class="lastBox" style="background-color: #f8fcff;">
    <div class="banner">
    </div>
    <el-card :body-style="{padding:'0' } " style="width:70.5%;margin: 20px auto;" class="box-card">
      <div style="float: left;">
        <el-row class="tac">
          <el-col :span="20">
            <div style="text-align: center;margin-bottom: 20px;">
              <p
                style="background: linear-gradient(0deg, #181A7D, #30C9CD, #5BE196);width: 240px;height: 72px;font-size: 24px;color: white;line-height: 72px;">
                关于我们</p>
            </div>
            <el-menu style="width: 240px;" :default-active="active" class="el-menu-vertical-demo" @select="handClick"
              @open="handleOpen" @close="handleClose" background-color="#fdfeff" text-color="#333333"
              active-text-color="#24a2aa">
              <el-menu-item index="1">
                <span slot="title">企业介绍</span>
              </el-menu-item>
              <el-menu-item index="2">
                <span slot="title">联系我们</span>
              </el-menu-item>
              <el-menu-item index="3">
                <span slot="title">荣誉资质</span>
              </el-menu-item>
              <el-menu-item index="4">
                <span slot="title">厂区风采</span>
              </el-menu-item>
              <el-menu-item index="5">
                <span slot="title">大事记</span>
              </el-menu-item>
              <el-menu-item index="6">
                <span slot="title">宣传视频</span>
              </el-menu-item>
            </el-menu>
          </el-col>
        </el-row>
      </div>
      <div class="lastBox-right">
        <!-- 企业介绍 -->
        <div class="enterprise" style="width: 84%;text-align: center;padding: 49px 0 50px 0;" v-if="value =='1'">
          <p
            style="font-size: 24px;margin-bottom: 19px;padding-bottom: 19px;border-bottom: 1px solid rgb(238, 238, 238);">
            企业介绍</p>
          <!-- <p class="p1" style="margin-bottom: 38px;"><span>发布人：张三</span><span>发布时间：2015-10-29</span><span style="margin: 0;">浏览次数：263</span></p> -->
          <div
            style="width: 100%; text-indent: 2em;text-align: left;letter-spacing: 1px;line-height: 32px;font-size: 18px;">
            <p>
              <span
                style="color: #24A2AA;">陕西省报废汽车回收拆解有限公司</span>为我省目前最大报废汽车处理企业，是经国家商务部备案，省商务厅，省工商局等部门批准，陕西省国资委系统陕西物流集团出资成立的汽车回收拆解资质的国有企业。公司位于西安市户县沣京工业园沣五路，一期项目占地98.98亩，注册资本5000万元。公司业务涵盖报废汽车整体回收与拆解、整车车壳及废钢汽车零部件再利用、废旧轮胎再利用、蓄电池修复再生；回收加工生产性、非生产性废旧金属和非金属等。
              公司建有现代化的综合办公楼、标准化生产厂房、报废汽车回收停放场；消防、安保、检测设备及水、电、通信等附属设施一应俱全；目前已安装4条国内最先进的报废汽车小车拆解流水线（CRS-II），并安装一条大车拆解线；该生产线是在国内首次实现了报废汽车流水线式、精细化、无害化（无需火焰切割）拆解，从而大大提高了拆解效率，降低了零部件拆解破碎率。
            <div style="text-align: center;margin: 20px 0 19px 0;">
              <img style="width: 580px;height: 440px;" src="/img/qiye1.png" alt="">
            </div>
            上述汽车拆解流水线设备可拆解市面上常见的所有车型，如轿车、微型面包车、SUV、公交车、货车等。投产后日拆解各类报废汽车能力可达60辆以上，年拆解报废汽车2万余辆，预计年销售收入1.2亿元。公司一期项目以报废汽车的回收、拆解和废旧金属回收加工为主营业务，通过规范的管理和精细化运作，带动西安市报废汽车拆解行业逐步正规化、专业化、规模化。二期项目着力于行业整合和产业链纵深延展，充分利用报废汽车这个优势资源，做好市场定位，打造自己的产品链，最终将企业打造成西北地区规模最大、效益最好，影响力最强的标杆性示范基地。
            <div style="text-align: center;margin: 20px 0 19px 0;">
              <img style="width: 580px;height: 440px;" src="/img/qiye2.png" alt="">
            </div>
            </p>
          </div>
        </div>
        <!-- 联系我们 -->
        <div class="enterprise" style="width: 84%;padding: 49px 0 50px 0;" v-if="value =='2'">
          <p style="font-size: 24px;padding-bottom: 19px;border-bottom: solid 1px #EEEEEE;text-align: center;">联系我们</p>
          <!-- <div style="margin: 20px 0 0 0;display: flex;justify-content: space-between;">
            <div>
              <p style="font-size: 24px;margin-bottom: 29px;">陕西省报废汽车回收拆解有限公司</p>
              <p style="font-size: 16px;margin-bottom: 20px;"><img style="width: 32px;height: 32px;display: inline-block;vertical-align: middle;margin-right: 10px;" src="/img/dizhi.png" alt=""><span>地址：陕西省西安市长安区雁翔路903号</span></p>
              <p style="font-size: 16px;margin-bottom: 20px;"><img style="width: 32px;height: 32px;display: inline-block;vertical-align: middle;margin-right: 10px;" src="/img/dianhua.png" alt=""><span>电话：400-855-0658</span></p>
              <p style="font-size: 16px;margin-bottom: 20px;"><img style="width: 32px;height: 32px;display: inline-block;vertical-align: middle;margin-right: 10px;" src="/img/chuanzhen.png" alt=""><span>传真：0755-8612-9126</span></p>
              <p style="font-size: 16px;margin-bottom: 20px;"><img style="width: 32px;height: 32px;display: inline-block;vertical-align: middle;margin-right: 10px;" src="/img/qq.png" alt=""><span>QQ：0755-8612-9126</span></p>
              <p style="font-size: 16px;margin-bottom: 20px;"><img style="width: 32px;height: 32px;display: inline-block;vertical-align: middle;margin-right: 10px;" src="/img/youxiang.png" alt=""><span>邮箱：0755-8612-9126</span></p>
              <p style="font-size: 16px;"><img style="width: 32px;height: 32px;display: inline-block;vertical-align: middle;margin-right: 10px;" src="/img/weixin.png" alt=""><span>微信公众号：0755-8612-9126</span></p>
            </div>
            <div style="width: 480px;height: 380px;">
              <img style="width: 100%;height: 100%;" src="/img/lianxi1.png" alt="">
            </div>
          </div> -->
          <div style="margin: 20px 0 0 0;display: flex;justify-content: space-between;">
            <div style="margin-right: 29px;">
              <p style="font-size: 16px;margin-bottom: 20px;">城西业务部</p>
              <div style="display: flex;">
                <img style="width: 32px;height: 32px;display: inline-block;vertical-align: middle;margin-right: 10px;"
                  src="/img/dizhi.png" alt="">
                <p style="font-size: 16px;margin-bottom: 20px;"><span>地址：西安市未央区三桥国际·汽车欢乐港内45排61号</span></p>
              </div>
              <p style="font-size: 16px;margin-bottom: 20px;"><img
                  style="width: 32px;height: 32px;display: inline-block;vertical-align: middle;margin-right: 10px;"
                  src="/img/dianhua.png" alt=""><span>电话：029-89108049</span></p>
              <img style="width: 100%;height: 360px;" src="/img/chengxi.jpg" alt="">
            </div>
            <div>
              <p style="font-size: 16px;margin-bottom: 20px;">城南业务部</p>
              <div style="display: flex;">
                <img style="width: 32px;height: 32px;display: inline-block;vertical-align: middle;margin-right: 10px;"
                  src="/img/dizhi.png" alt="">
                <p style="font-size: 16px;margin-bottom: 20px;"><span>地址：西安市长安区雁翔路903号西安再生资源市场</span></p>
              </div>
              <p style="font-size: 16px;margin-bottom: 20px;"><img
                  style="width: 32px;height: 32px;display: inline-block;vertical-align: middle;margin-right: 10px;"
                  src="/img/dianhua.png" alt=""><span>电话：029-89683700</span></p>
              <img style="width: 100%;height: 360px;" src="/img/chengnan.jpg" alt="">
            </div>
          </div>
          <!-- <p style="font-size: 24px;padding: 49px 0 19px 0;border-bottom: solid 1px #EEEEEE;text-align: center;">其他联系方式</p> -->
          <div style="margin: 20px 0 0 0;display: flex;justify-content: space-between;">
            <div style="margin-right: 29px;">
              <p style="font-size: 16px;margin-bottom: 20px;">城东业务部</p>
              <div style="display: flex;">
                <img style="width: 32px;height: 32px;display: inline-block;vertical-align: middle;margin-right: 10px;"
                  src="/img/dizhi.png" alt="">
                <p style="font-size: 16px;margin-bottom: 20px;"><span>地址：西安市新城区石家街中企钢贸城内东排109号</span></p>
              </div>
              <p style="font-size: 16px;margin-bottom: 20px;"><img
                  style="width: 32px;height: 32px;display: inline-block;vertical-align: middle;margin-right: 10px;"
                  src="/img/dianhua.png" alt=""><span>电话：029-84245233</span></p>
              <img style="width: 100%;height: 360px;" src="/img/chengdong.jpg" alt="">
            </div>
            <div>
              <p style="font-size: 16px;margin-bottom: 20px;">城北业务部</p>
              <div style="display: flex;">
                <img style="width: 32px;height: 32px;display: inline-block;vertical-align: middle;margin-right: 10px;"
                  src="/img/dizhi.png" alt="">
                <p style="font-size: 16px;margin-bottom: 20px;"><span>地址：西安市未央区名京九合院8号楼1层东1号</span></p>
              </div>
              <p style="font-size: 16px;margin-bottom: 20px;"><img
                  style="width: 32px;height: 32px;display: inline-block;vertical-align: middle;margin-right: 10px;"
                  src="/img/dianhua.png" alt=""><span>电话：029-86452034</span></p>
              <img style="width: 100%;height: 360px;" src="/img/chengbei.jpg" alt="">
            </div>
          </div>
          <div style="margin: 20px 0 0 0;display: flex;justify-content: space-between;">
            <div style="margin-right: 29px;">
              <p style="font-size: 16px;margin-bottom: 20px;">报废汽车回收拆解有限公司 （总部）</p>
              <div style="display: flex;">
                <img style="width: 32px;height: 32px;display: inline-block;vertical-align: middle;margin-right: 10px;"
                  src="/img/dizhi.png" alt="">
                <p style="font-size: 16px;margin-bottom: 20px;"><span>地址：西安市鄠邑区沣京工业园沣五路</span></p>
              </div>
              <p style="font-size: 16px;margin-bottom: 20px;"><img
                  style="width: 32px;height: 32px;display: inline-block;vertical-align: middle;margin-right: 10px;"
                  src="/img/dianhua.png" alt=""><span>电话：029-62521513/029-62521512</span></p>
              <img style="width: 100%;height: 360px;" src="/img/zongbu.jpg" alt="">
            </div>
          </div>
        </div>
        <!-- 荣誉资质 -->
        <div class="enterprise" style="width: 84%;padding: 49px 0 50px 0;" v-if="value =='3'">
          <p style="font-size: 24px;padding-bottom: 19px;border-bottom: solid 1px #EEEEEE;text-align: center;">荣誉资质</p>
          <div style="margin-top: 20px;">
            <div style="width: 100%;height: 540px;margin-bottom: 20px;">
              <img style="width: 100%;height: 100%;" src="/img/rongyu1.png" alt="">
            </div>
            <div style="width: 100%;height: 540px;margin-bottom: 20px;">
              <img style="width: 100%;height: 100%;" src="/img/rongyu2.png" alt="">
            </div>
          </div>
        </div>
        <!-- 厂区风采 -->
        <div class="enterprise" style="width: 84%;padding: 49px 0 50px 0;" v-if="value =='4'">
          <p style="font-size: 24px;padding-bottom: 19px;border-bottom: solid 1px #EEEEEE;text-align: center;">厂区风采</p>
          <div style="margin-top: 20px;">
            <div style="width: 100%;height: 540px;margin-bottom: 20px;">
              <img style="width: 100%;height: 100%;" src="/img/changqu.png" alt="">
            </div>
            <div style="width: 100%;height: 540px;margin-bottom: 20px;">
              <img style="width: 100%;height: 100%;" src="/img/changqu.png" alt="">
            </div>
          </div>
        </div>
        <!-- 大事记 -->
        <div class="enterprise" style="width: 84%;padding: 49px 0 50px 0;height: 3100px;" v-if="value =='5'">
          <p style="font-size: 24px;padding-bottom: 19px;border-bottom: solid 1px #EEEEEE;text-align: center;">大事记</p>
          <div style="margin-top: 20px;">
            <div class='time-line'>
              <div class='time-line-div'>
                <p><span class="sp1">2016年07月19日</span><span class="sp2">正式开业</span><img class="img1"
                    src="/img/dashiji2.png" alt=""></p>
                <p ref='circular'></p>
                <p ref='circular'></p>
                <p><span class="_sp1">2017年02月15日</span><span class="_sp2"
                    style="line-height: 30px;">开展“大干100天，争创开门红”活动</span><img class="img1" src="/img/dashiji3.png"
                    alt=""></p>
              </div>
              <div class='time-line-div' style="top: 200px;">
                <p><span class="sp1">2017年03月10日</span><span class="sp2"
                    style="line-height: 30px;">中国物资再生协会副会长兼秘书长高延莉女士、陕西省物资再生利用行业协会会长周景存来公司考察调研。</span></p>
                <p ref='circular'></p>
                <p ref='circular'></p>
                <p><span class="_sp1">2017年3月31日</span><span class="_sp2"
                    style="line-height: 30px;">由公司主办的陕西省报废机动车回收拆解专业委员会第一次会议在西安止园饭店成功举行，会议通过《陕西省报废机动车回收拆解行业自律公约》。</span>
                </p>
              </div>
              <div class='time-line-div' style="top: 400px;">
                <p><span class="sp1">2017年05月18日</span><span class="sp2" style="line-height: 30px;">人大代表视察工作</span></p>
                <p ref='circular'></p>
                <p ref='circular'></p>
                <p><span class="_sp1">2017年08月29日</span><span class="_sp2"
                    style="line-height: 30px;">组织参加在宝鸡建国饭店召开的陕西省报废机动车回收拆解专业委员会第二次会议，会议通过《陕西省报废机动车回收拆解行业自律公约实施细则》。</span>
                </p>
              </div>
              <div class='time-line-div' style="top: 600px;">
                <p><span class="sp1">2017年10月25日</span><span class="sp2"
                    style="line-height: 30px;">陕西省报废汽车回收拆解有限公司二手配件淘宝店铺正式上线。</span></p>
                <p ref='circular'></p>
                <p ref='circular'></p>
                <p><span class="_sp1">2017年11月03日</span><span class="_sp2"
                    style="line-height: 30px;">参加由户县统战部、工商联举办的“鄠邑区市考指标民间投资项目统计推进会”。</span></p>
              </div>
              <div class='time-line-div' style="top: 800px;">
                <p><span class="sp1">2017年11月10日</span><span class="sp2"
                    style="line-height: 30px;">陕西省物资再生利用协会第三届会员代表大会</span></p>
                <p ref='circular'></p>
                <p ref='circular'></p>
                <p><span class="_sp1">2017年12月29日</span><span class="_sp2" style="line-height: 30px;">举办首届职工运动会</span>
                </p>
              </div>
              <div class='time-line-div' style="top: 1000px;">
                <p><span class="sp1">2018年04月10日</span><span class="sp2" style="line-height: 30px;">曲江国际会展中心参展3日</span>
                </p>
                <p ref='circular'></p>
                <p ref='circular'></p>
                <p><span class="_sp1">2018年4月18日</span><span class="_sp2"
                    style="line-height: 30px;">公司改扩建项目开工仪式成功举办</span></p>
              </div>
              <div class='time-line-div' style="top: 1200px;">
                <p><span class="sp1">2018年5月19日</span><span class="sp2" style="line-height: 30px;">“走进社区”宣传活动正式启动</span>
                </p>
                <p ref='circular'></p>
                <p ref='circular'></p>
                <p><span class="_sp1">2018年6月</span><span class="_sp2"
                    style="line-height: 30px;">公司获得国资委系统先进基础党组织</span></p>
              </div>
              <div class='time-line-div' style="top: 1400px;">
                <p><span class="sp1">2018年6月29日</span><span class="sp2"
                    style="line-height: 30px;">庆祝建党97周年暨七一党员表彰座谈会</span></p>
                <p ref='circular'></p>
                <p ref='circular'></p>
                <p><span class="_sp1">2018年12月22日</span><span class="_sp2"
                    style="line-height: 30px;">西安市2018年高排放老旧机动车补贴政策正式启动</span></p>
              </div>
              <div class='time-line-div' style="top: 1600px;">
                <p><span class="sp1">2019年3月31日</span><span class="sp2"
                    style="line-height: 30px;">高排放阶段性取得成果，累计收车30000吨</span></p>
                <p ref='circular'></p>
                <p ref='circular'></p>
                <p><span class="_sp1">2019年11月8日</span><span class="_sp2"
                    style="line-height: 30px;">陕西省物资再生利用行业协会专家委员会成立大会在公司召开</span></p>
              </div>
              <div class='time-line-div' style="top: 1800px;">
                <p><span class="sp1">2020年4月</span><span class="sp2"
                    style="line-height: 30px;">公司正式启动对商洛市磊宝报废汽车回收拆解有限责任公司的股权收购工作。</span></p>
                <p ref='circular'></p>
                <p ref='circular'></p>
                <p><span class="_sp1">2020年6月19日</span><span class="_sp2" style="line-height: 30px;">我公司党支部升格党委</span>
                </p>
              </div>
              <div class='time-line-div' style="top: 2000px;">
                <p><span class="sp1">2020年10月</span><span class="sp2"
                    style="line-height: 30px;">我公司精细化拆解升级改造项目正式开工。</span></p>
                <p ref='circular'></p>
                <!-- <p ref='circular'></p>
                <p><span class="_sp1">2020年6月19日</span><span class="_sp2" style="line-height: 30px;">我公司党支部升格党委</span></p> -->
              </div>
              <div class='img-dotted' ref='dotted'></div>
            </div>
          </div>
        </div>
        <!-- 宣传视频 -->
        <div class="enterprise" style="width: 72%;padding: 49px 0 100px 0;" v-if="value =='6'">
          <p style="font-size: 24px;padding-bottom: 19px;border-bottom: solid 1px #EEEEEE;text-align: center;">宣传视频</p>
          <div style="margin-top: 20px;">
            <div style="width: 100%;">
              <video-player class="video-player vjs-custom-skin" ref="videoPlayer" :playsinline="true"
                :options="playerOptions(url+'UploadFiles/static/video/1.mp4')">
              </video-player>
              <p style="font-size: 16px;margin-top: 10px;margin-bottom: 10px;color: #666666;text-align: center;">
                陕西省报废汽车回收拆解有限公司</p>
            </div>
            <div style="width: 100%;">
              <video-player class="video-player vjs-custom-skin" ref="videoPlayer" :playsinline="true"
                :options="playerOptions(url+'UploadFiles/static/video/2.mp4')">
              </video-player>
              <p style="font-size: 16px;margin-top: 10px;color: #666666;text-align: center;">我和我的祖国</p>
            </div>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "adressMaintain",
  data() {
    return {
      currentPage3: 1,
      value: "",
      activeName: "1",
      activeName2: "12",
      activeName3: "22",
      form: {
        name: "",
        type: [],
        desc: "",
      },
      url: "",
    };
  },
  computed: {
    playerOptions() {
      return function (srcUrl) {
        const playerOptionsObj = {
          playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
          autoplay: false, //如果true,浏览器准备好时开始回放。
          muted: false, // 默认情况下将会消除任何音频。
          loop: false, // 导致视频一结束就重新开始。
          preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          language: "zh-CN",
          aspectRatio: "9:4", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
          sources: [
            {
              type: "video/mp4",
              src: srcUrl,
            },
            // {
            //   type: 'application/x-mpegURL',
            //   src: this.playUrl
          ],
          poster: "", //你的封面地址
          // width: document.documentElement.clientWidth,
          notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
          controlBar: {
            timeDivider: true,
            durationDisplay: true,
            remainingTimeDisplay: false,
            fullscreenToggle: true, //全屏按钮
          },
        };
        return playerOptionsObj;
      };
    },
  },
  created() {
    this.value = this.$route.query.value;
    this.active = this.$route.query.value;
    if (this.value == undefined) {
      this.value = 1;
    }
    if (this.active == undefined) {
      this.active = "1";
    }
    this.url = process.env.VUE_APP_API_BASE_URL;
  },
  mounted() {
    // this.$refs.dotted.style.left = this.$refs.circular[0].offsetLeft - 104 + 'px'
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    handClick(index) {
      console.log(index);
      if (index == 1) {
        this.value = 1;
      }
      if (index == 2) {
        this.value = 2;
      }
      if (index == 3) {
        this.value = 3;
      }
      if (index == 4) {
        this.value = 4;
      }
      if (index == 5) {
        this.value = 5;
      }
      if (index == 6) {
        this.value = 6;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.banner {
  text-align: center;
  height: 480px;
  background-image: url(/img/lianxibg.png);
  background-size: 100%;
  line-height: 480px;
}
.lastBox-right {
  overflow: hidden;
}
.enterprise {
  margin: 0 auto;
  .p1 {
    span {
      font-size: 14px;
      margin-right: 29px;
    }
  }
}

.time h2 {
  color: #ff6600;
  margin: 20px auto 30px auto;
}
.time-line {
  position: relative;
  width: 215px;
  margin: 0 auto;
  margin-top: 84px;
}
.time-line-div {
  position: relative;
  min-height: 85px;
}
.time-line-div > p:nth-child(1) {
  position: absolute;
  left: -200px;
  width: 240px;
  text-align: right;
  .sp1 {
    font-size: 24px;
    display: block;
    margin-bottom: 29px;
  }
  .sp2 {
    font-size: 16px;
    display: block;
    margin-bottom: 15px;
  }
  .img1 {
    width: 180px;
    height: 135px;
    position: absolute;
    left: -90px;
  }
}
.time-line-div > p:nth-child(2) {
  position: absolute;
  left: 100px;
  width: 16px;
  height: 16px;
  top: 10px;
  // background:#5CB85C;
  // border-radius: 50%;
  z-index: 10;
  background: url(/img/dashiji1.png);
}
.time-line-div > p:nth-child(3) {
  position: absolute;
  left: 100px;
  width: 16px;
  height: 16px;
  top: 120px;
  // background:#5CB85C;
  // border-radius: 50%;
  z-index: 10;
  background: url(/img/dashiji1.png);
}
.time-line-div > p:nth-child(4) {
  position: absolute;
  left: 170px;
  padding: 10px;
  font-size: 24px;
  border-radius: 10px;
  width: 225px;
  top: 105px;
  ._sp1 {
    font-size: 24px;
    display: block;
    margin-bottom: 29px;
  }
  ._sp2 {
    font-size: 16px;
    display: block;
    margin-bottom: 15px;
  }
  .img1 {
    width: 180px;
    height: 135px;
    position: absolute;
    left: 200px;
  }
}
.img-dotted {
  position: absolute;
  width: 1px;
  height: 3000px;
  top: 0;
  z-index: 1;
  background: #dddddd;
  left: 107.5px;
  // background:url('/static/images/vue/dotted.png');
}
.time-line-detail > p {
  margin-left: 30px;
  margin-bottom: 10px;
}

/deep/.el-menu-item.is-active {
  background-color: #24a2aa !important;
  color: white !important;
}
/deep/.el-timeline-item__timestamp.is-top {
  position: absolute;
  left: -117px;
  top: -3px;
  color: #333333;
}
/deep/.el-timeline {
  padding-left: 150px;
}
.vjs-custom-skin {
  width: 100%;
  height: 100%;
}
</style>
